import * as React from 'react'
import LogoVertere from '../images/logo-vertere.svg'
import Facebook from '../images/icons/facebook.svg'
import Instagram from '../images/icons/instagram.svg'

import {
  styleFooter,
  container,
  columns,
  column,
  copy
} from '../components/styles/Footer.style'

const Footer = () => {
  return (
    <div css={styleFooter}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <div style={{ display: 'flex' }}>
              <div css={copy}>
                Realização:
                <a href='https://institutovertere.org/' target='_blank' rel='noopener noreferrer'><img src={LogoVertere} alt='/' /></a>
              </div>
              <div style={{ marginLeft: 'auto', display: 'flex' }}>
                <a href='https://www.facebook.com/redacaosolidaria.org' target='_blank' rel='noopener noreferrer'><img src={Facebook} alt='/' style={{ marginRight: 8 }} /></a>
                <a href='https://www.instagram.com/redacao.solidaria/' target='_blank' rel='noopener noreferrer'><img src={Instagram} alt='/' style={{ marginLeft: 8 }} /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer