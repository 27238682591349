import * as React from 'react'
import { globalStyles } from '../components/layout.styles'
import { Global } from '@emotion/react'
import NavBar from '../components/NavBar'
import SEO from "../components/Seo"
import Cadastro from '../components/CadastreSe'
import Footer from '../components/Footer'

const CadastreSe = () => {
  return (
    <div>
      <Global styles={globalStyles} />
      <SEO title="Redação Solidária | Rafael Riemma" />
      <NavBar />
      <Cadastro />
      <Footer />
    </div>
  )
}

export default CadastreSe