import * as React from 'react'
import Button from '../components/StartNowBtn'
import Img1 from '../images/cadastro.png'

import {
  column,
  columns,
  container,
  header,
  content,
  mobile,
  label,
  formulario
} from '../components/styles/CadastreSe.styles'

const Header = () => {
  return (
    <div css={[header, content]}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <h1>FAÇA O SEU <span>PRÉ-CADASTRO</span> E ACOMPANHE OS PRÓXIMOS PASSOS NA SUA CAIXA DE ENTRADA!</h1>
            <div css={formulario}>
              <form method="post" action="#" data-netlify="true" name="cadastre-se" style={{ margin: '0 auto' }}>
                <input type="hidden" name="form-name" value="cadastre-se"  />
                <div css={label}>
                  <input placeholder='Nome completo' type="text" name="name" id="name" required />
                </div>
                <div css={label}>
                  <input placeholder='E-mail' type="text" name="email" id="email" required />
                </div>
                <div css={label}>
                  <input placeholder='Telefone' type="text" name="phone" id="phone" required />
                </div>
                <div css={label}>
                  <input placeholder='Estado' type="text" name="state" id="state" required />
                </div>
                <div css={label}>
                  <input placeholder='Cidade' type="text" name="city" id="city" required />
                </div>
                <div type='submit' style={{ display: 'flex', marginTop: 60 }}><Button>CONFIRMAR</Button></div>
              </form> 
            </div>
          </div>
          <div css={column}>
            <img src={Img1} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header