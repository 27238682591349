import * as React from 'react'

import {
  btnStartNow
} from '../components/styles/Buttons.styles'

const StartNowBtn = ({ children }) => (
  <button css={btnStartNow}>{ children }</button>
)

export default StartNowBtn
